import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';

let additionalDocUpdated = false;
let updatedListItem = null;

document.getElementById('additionalDocModal').addEventListener('hidden.bs.modal', async function (event) {
  var form = event.target.querySelector('form');
  form.reset();
  form.querySelector('#adFileNameRow').setAttribute('hidden', true);
  form.querySelector('#adMimeRow').setAttribute('hidden', true);
  additionalDocUpdated = false;
  updatedListItem = null;
});

document.getElementById('additionalDocModal').addEventListener('show.bs.modal', async function () {
  document.getElementById('adFile').addEventListener('change', async function(event) {
    onAdditionalFileChange(event);
  });
});

function editAdditionalDoc(event) {
  if (event.target.className.includes('btn')) return;

  additionalDocUpdated = true;

  var listItem = event.target instanceof HTMLSpanElement ? event.target.parentElement : event.target;
  updatedListItem = listItem;
  var modalForm = document.getElementById('additionalDocsForm');

  copyOldValue(listItem, modalForm, 'id');
  copyOldValue(listItem, modalForm, 'description');
  var oldFile = listItem.querySelector('input[name$="additionalDocList.document"]');
  var clone = oldFile.cloneNode(true);
  clone.removeAttribute('hidden');
  var formFile = modalForm.querySelector('input[name="additionalDocList.document"]');
  formFile.parentNode.replaceChild(clone, formFile);
  var oldMimeSelect = listItem.querySelector('select[name="additionalDocList.mimeCode"]');
  var formMimeSelect = modalForm.querySelector('select[name="additionalDocList.mimeCode"]');
  copySelection(oldMimeSelect, formMimeSelect);
  copyOldValue(listItem, modalForm, 'fileName');
  copyOldValue(listItem, modalForm, 'externalURI');
  if (document.getElementById('adFile').value != '') {
    unhideFileNameAndMimeTypeInputs();
  }

  document.getElementById('add-additional-doc').click();
}

function copyOldValue(listItem, form, name) {
  var oldValue = listItem.querySelector(`input[name$="additionalDocList.${name}"]`).value;
  form.querySelector(`input[name="additionalDocList.${name}"]`).value = oldValue;
}

function copySelection(oldSelect, newSelect) {
  Array.from(oldSelect.options).forEach((option, index) => {
    newSelect.options[index].selected = option.selected;
  });
}

window.saveOrUpdateAdditionalDoc = function () {
  const valid = validateInputs('additionalDocsForm');
  if (!valid) return;

  document.getElementById('additionalDocModal-close').click();

  var li;
  var textSpan;
  if (!additionalDocUpdated) {
    var remButton = document.createElement('a');
    remButton.className = 'btn btn-outline-primary';
    remButton.setAttribute('type', 'button');
    remButton.textContent = document.getElementById('additionalDoc-localized-remove-button-text').textContent;
    remButton.onclick = function() {
      this.parentNode.remove();
    };

    textSpan = document.createElement('span');

    li = document.createElement('li');
    li.className = 'list-group-item d-flex justify-content-between align-items-center';
    li.appendChild(textSpan);
    li.appendChild(remButton);
    li.addEventListener('click', editAdditionalDoc);
  } else {
    li = updatedListItem;
    textSpan = li.querySelector('span');

    // Clean old values
    const inputsAndSelects = li.querySelectorAll('input, select');
    inputsAndSelects.forEach(element => element.remove());
  }
  textSpan.textContent = document.getElementById('did').value;

  // Clone form inputs as hidden list inputs
  Array.from(document.getElementById('additionalDocsForm').elements).forEach(input => {
    var clone = input.cloneNode(true);
    clone.setAttribute('hidden', true);
    clone.removeAttribute('required');
    if (input.tagName.toLowerCase() === 'select') {
      copySelection(input, clone);
    } else if (input.type === 'text') {
      input.value = input.value.trim();
    }
    li.appendChild(clone);
  });

  if (!additionalDocUpdated) {
    var docsList = document.getElementById('additional-docs-list');
    docsList.appendChild(li);
  }
};

function onAdditionalFileChange(event) {
  var fileInput = event.target;
  if (fileInput.files.length > 0) {
    var file = fileInput.files[0];
    var adFileInput = document.getElementById('adFileName');
    adFileInput.value = file.name;
    adFileInput.setAttribute('required', true);
    var adMimeInput = document.getElementById('adMime');
    adMimeInput.value = file.type;
    adMimeInput.setAttribute('required', true);
    unhideFileNameAndMimeTypeInputs()
  } else {
    var adFileInput = document.getElementById('adFileName');
    adFileInput.value = '';
    adFileInput.removeAttribute('required');
    document.getElementById('adFileNameRow').setAttribute('hidden', true);
    var adMimeInput = document.getElementById('adMime');
    adMimeInput.value = '';
    adMimeInput.removeAttribute('required');
    document.getElementById('adMimeRow').setAttribute('hidden', true);
  }
};

function unhideFileNameAndMimeTypeInputs() {
  document.getElementById('adFileNameRow').removeAttribute('hidden');
  document.getElementById('adMimeRow').removeAttribute('hidden');
}