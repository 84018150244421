window.makeOtherRequired = function (dependantIds, inputIds, emptyDepIds) {
  var emptyDepHasValue = false;
  if (emptyDepIds) {
    emptyDepHasValue = emptyDepIds.some(id => {
      const inputElement = document.getElementById(id);
      return inputElement && inputElement.value.trim() !== '';
    });
  }

  var dependantsHasValue = false
  if (!emptyDepHasValue) {
    dependantsHasValue = dependantIds.some(id => {
      const inputElement = document.getElementById(id);
      return inputElement && inputElement.value.trim() !== '';
    });
  }

  inputIds.forEach(id => {
    const input = document.getElementById(id);
    const span = document.getElementById(id + '-l');
    if (!emptyDepHasValue && dependantsHasValue) {
      input.setAttribute('required', true);
      span.removeAttribute('hidden');
    } else {
      input.removeAttribute('required');
      span.setAttribute('hidden', true);
    }
  });

}
