function updateCounters(list) {
  const listItems = list.querySelectorAll(':scope > .list-group-item');
  listItems.forEach((item, index) => {
    let counterSpan = item.querySelector('.counter-value');
    const counter = (index + 1)
    counterSpan.textContent = counter;

    updateCollapsibleSectionID(item, counter);
  });
}

function updateCollapsibleSectionID(parent, counter) {
  // Update collapse section link in collapse header
  const aElement = parent.querySelector('.section-header');
  const href = aElement.getAttribute('href');
  aElement.setAttribute('href', href.slice(0, -1) + counter);

  // Update collapse section ID
  const divElement = parent.querySelector('.collapse');
  const id = divElement.getAttribute('id');
  divElement.setAttribute('id', id.slice(0, -1) + counter);
}

window.addItem = function(button, dynamicItemId, listId) {
  const commonParent = button.parentElement;
  const list = commonParent.querySelector(`#${listId}`);
  const item = document.getElementById(dynamicItemId);
  var itemClone = item.cloneNode(true);
  itemClone.removeAttribute('hidden');
  itemClone.removeAttribute('id');
  const collapse = itemClone.querySelector('.collapse');
  if (!collapse.classList.contains('show')) {
    collapse.classList.add('show');
  }
  list.appendChild(itemClone);
  updateCounters(list);
}

window.restrictNearbyEndDate = function(startDate, endDateId) {
  const endDate = startDate.parentElement.parentElement.parentElement.querySelector(`#${endDateId}`);
  if (startDate.value != '') {
    endDate.setAttribute('min', startDate.value);
  } else {
    endDate.removeAttribute('min');
  }
};

window.makeNearbyRequired = function (currentElement, dependantIds, inputIds, emptyDepIds) {
  const commonParent = currentElement.parentElement.parentElement.parentElement.parentElement;
  var emptyDepHasValue = false;
  if (emptyDepIds) {
    emptyDepHasValue = emptyDepIds.some(id => {
      const inputElement = commonParent.querySelector(`#${id}`);
      return inputElement && inputElement.value.trim() !== '';
    });
  }

  var dependantsHasValue = false
  if (!emptyDepHasValue) {
    dependantsHasValue = dependantIds.some(id => {
      const inputElement = commonParent.querySelector(`#${id}`);
      return inputElement && inputElement.value.trim() !== '';
    });
  }

  inputIds.forEach(id => {
    const input = commonParent.querySelector(`#${id}`);
    const span = commonParent.querySelector(`#${id}-l`);
    if (!emptyDepHasValue && dependantsHasValue) {
      input.setAttribute('required', true);
      span.removeAttribute('hidden');
    } else {
      input.removeAttribute('required');
      span.setAttribute('hidden', true);
    }
  });

}

window.chargeIndicatorSwitch = function(indicatorInput) {
  const indicator = indicatorInput.value;
  const commonParent = indicatorInput.parentElement.parentElement.parentElement;
  const uncl5189 = commonParent.querySelector('#order-line-ac-rc-uncl5189');
  const uncl7161 = commonParent.querySelector('#order-line-ac-rc-uncl7161');
  if (indicator === 'true') {
    uncl5189.setAttribute('hidden', true);
    uncl5189.value = '';
    uncl7161.removeAttribute('hidden');
  } else {
    uncl7161.setAttribute('hidden', true);
    uncl7161.value = '';
    uncl5189.removeAttribute('hidden');
  }
};