import 'bootstrap/dist/css/bootstrap.min.css';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';

document.getElementById('x-order-form').addEventListener('submit', async function(event) {
  event.preventDefault(); // Prevent the default form submission

  const form = event.target;
  const formData = new FormData(form);
  const filteredFormData = new FormData();

  // Create JSON objects from the list elements and add them to the form
  await addListAsJson('additional-docs-list', 'additionalDocList', filteredFormData);
  await addListAsJson('allowance-charge-list', 'allowanceChargeInfoList', filteredFormData);
  await addListAsJson('dynamic-order-line-list', 'orderLineList', filteredFormData);

  // Filter out empty and list values
  for (let [key, value] of formData.entries()) {
    if (!key.startsWith('additionalDocList.')
        && !key.startsWith('allowanceChargeInfoList.')
        && !key.startsWith('orderLineList.')
        && ((value instanceof File && value != '')
        || (value instanceof Date && value != '')
        || (!(value instanceof File) && !(value instanceof Date) && value.trim() !== ''))) {
      var convertedValue = await getValueOrFileAsBase64String(value);
      filteredFormData.append(key, convertedValue);
    }
  }

  try {
    const response = await fetch('/generator', {
      method: 'POST',
      body: filteredFormData
    });

    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText);
    }

    // Access a header parameter; in this case, Content-Disposition
    const contentDisposition = response.headers.get('Content-Disposition');
    let filename = 'XBestellung.xml'; // A default filename if none is specified
    // Extract filename from Content-Disposition header if present
    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
      if (filenameMatch && filenameMatch[1]) {
        filename = filenameMatch[1].replace(/['"]/g, ''); // Remove any surrounding quotes
      }
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // Set the filename for the download
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  } catch (error) {
    console.error('Error:', error);
    showToast('Unexpected error. Please try again later or with different parameters.');
  }
});

function getValueOrFileAsBase64String(value) {
  return new Promise((resolve, reject) => {
    if (value instanceof File) {
      const reader = new FileReader();
      reader.onload = function(event) {
        const dataURL = event.target.result;
        const base64String = dataURL.split(',')[1];
        resolve(base64String);
      };
      reader.onerror = function(error) {
        reject(error);
      };
      reader.readAsDataURL(value);
    } else {
      const finalVal = value instanceof Date ? value : value.trim();
      resolve(finalVal);
    }
  });
}

async function addListAsJson(id, objectName, formData) {
  const listItems = document.querySelectorAll(`#${id} > li`);
  for (const item of listItems) {
    var jsonElement = {};
    await processElement(item, jsonElement);
    formData.append(objectName, JSON.stringify(jsonElement));
  }

  async function processElement(element, parentObject) {
    // Only select direct child inputs and selects
    var inputs = element.querySelectorAll('input, select');
    inputs = filterOutNestedLists(inputs, element);
    var listName;
    for (const input of inputs) {
      if (input.value !== '') {
        if (!listName) listName = findCommonListName(input.name);
        var path = listName
            ? input.name.slice(listName.length + 1).split('.')
            : input.name.split('.').slice(1); // Remove the first part - most parent object name
        await setValueAtPath(parentObject, path, input.type === 'file'
            ? await getValueOrFileAsBase64String(input.files[0])
            : input.value);
      }
    }
    // Process nested lists as json arrays
    const nestedLists = element.querySelectorAll('div > div > div > div > ul');
    for (const list of nestedLists) {
      var innerJsonArray = [];
      var arrayName;
      for (const listItem of list.querySelectorAll(':scope > li')) {
        const listItemObject = {};
        arrayName = await processElement(listItem, listItemObject); // Recursive call
        if (Object.keys(listItemObject).length > 0) innerJsonArray.push(listItemObject);
      }
      if (innerJsonArray.length > 0) {
        setValueAtPath(parentObject, findLastListPath(arrayName), innerJsonArray);
      }
    }
    return listName;
  }

  function filterOutNestedLists(inputs, parent) {
    return Array.from(inputs).filter(el => {
      let parent = el.parentNode;
      while (parent && parent !== parent) {
        if (parent.tagName.toLowerCase() === 'li') {
          return false;
        }
        parent = parent.parentNode;
      }
      return true;
    });
  }

  function extractArrayName(listItem) {
    const inputs = listItem.querySelectorAll(':scope > input, :scope > select, :scope > div > div > div > div > input, :scope > div > div > div > div > select');
    var parentListName;
    for (const input of inputs) {
      parentListName = findCommonNameStart(parentListName, input.name);
    }
    return parentListName;
  }

  function findCommonListName(name) {
    const lastIndex = name.lastIndexOf('List');
    if (lastIndex === -1) return name; // List not found
    return name.substring(0, lastIndex + 4);
  }

  function findLastListPath(arrayName) {
    const lastIndex = arrayName.lastIndexOf('List.');
    if (lastIndex === -1) return arrayName.split('.').slice(1); // Intermediate list not found
    return arrayName.substring(lastIndex + 5).split('.');
  }

  function setValueAtPath(obj, pathArray, value) {
    let current = obj;
    for (let i = 0; i < pathArray.length - 1; i++) {
      const part = pathArray[i];
      if (!current[part]) current[part] = {};
      current = current[part];
    }
    current[pathArray[pathArray.length - 1]] = value;
  }
}

function showToast(message) {
  const errorToast = document.getElementById('error-toast');
  const toast = new bootstrap.Toast(errorToast);
  toast.show();
};

window.focusInvalidInput = function() {
  const form = document.getElementById('x-order-form');
  const inputs = form.querySelectorAll('input, select, textarea');

  for (let input of inputs) {
    if (!input.checkValidity()) {
      let parent = input.parentElement;
      while (parent !== form) {
        if (parent.classList.contains('collapse')) {
          parent.classList.add('show');
        }
        parent = parent.parentElement;
      }
      input.focus();
      break;
    }
  }
};

window.allowOnlyKeysForWholeNumbers = function (event) {
  // Allow: backspace, delete, tab, escape, enter, and arrow keys
  restrictKeys(event, [46, 8, 9, 27, 13, 37, 38, 39, 40]);
}

window.allowOnlyKeysForFractionalNumbers = function (event) {
  // Allow: backspace, delete, tab, escape, enter, arrow keys, and decimal point (period, comma and numpad decimal)
  restrictKeys(event, [46, 8, 9, 27, 13, 37, 38, 39, 40, 110, 190, 188]);
}

function restrictKeys(event, allowedKeys) {
  const isCtrlAllowed = event.ctrlKey && [65, 67, 86, 88].indexOf(event.keyCode) > -1; // Ctrl+A, Ctrl+C, Ctrl+V, Ctrl+X
  const isDigit = (event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105); // Top row digits and numpad digits

  if (!allowedKeys.includes(event.keyCode) && !isCtrlAllowed && !isDigit) {
    event.preventDefault();
  }

}