import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';

let allowanceChargeUpdated = false;
let updatedACListItem = null;
let allowanceChargeListNumber = 0;

document.getElementById('ac-id').addEventListener('change', async function(event) {
  const indicator = event.target.value;
  const uncl5189 = document.getElementById('ac-id-uncl5189');
  const uncl7161 = document.getElementById('ac-id-uncl7161');
  if (indicator === 'true') {
    uncl5189.setAttribute('hidden', true);
    uncl5189.value = '';
    uncl7161.removeAttribute('hidden');
  } else {
    uncl7161.setAttribute('hidden', true);
    uncl7161.value = '';
    uncl5189.removeAttribute('hidden');
  }
});

document.getElementById('allowanceChargeModal').addEventListener('hidden.bs.modal', async function (event) {
  var form = event.target.querySelector('form');
  form.reset();
  allowanceChargeUpdated = false;
  updatedACListItem = null;
});

function editAllowanceCharge(event) {
  if (event.target.className.includes('btn')) return;

  allowanceChargeUpdated = true;

  var listItem = event.target instanceof HTMLSpanElement ? event.target.parentElement : event.target;
  updatedACListItem = listItem;
  var modalForm = document.getElementById('allowanceChargeForm');

  copyInputs(listItem, modalForm);

  document.getElementById('add-allowance-charge').click();
}

function copyInputs(fromContainer, toContainer) {
  const inputs = fromContainer.querySelectorAll('input');
  inputs.forEach(input => toContainer.querySelector(`input[name="${input.name}"]`).value = input.value);

  const selects = fromContainer.querySelectorAll('select');
  selects.forEach(select => copySelection(select, toContainer.querySelector(`select[name="${select.name}"]`)));
}

function copySelection(oldSelect, newSelect) {
  Array.from(oldSelect.options).forEach((option, index) => {
    newSelect.options[index].selected = option.selected;
  });
}

window.validateInputs = function (formName) {
  const form = document.getElementById(formName);
  var valid = form.checkValidity();
  if (!valid) {
    form.reportValidity();
  }
  return valid;
};

window.saveOrUpdateAllowanceCharge = function (event) {
  const valid = validateInputs('allowanceChargeForm');
  if (!valid) return;

  document.getElementById('allowanceChargeModal-close').click();

  var li;
  var textSpan;
  if (!allowanceChargeUpdated) {
    allowanceChargeListNumber++;
    var remButton = document.createElement('a');
    remButton.className = 'btn btn-outline-primary';
    remButton.setAttribute('type', 'button');
    remButton.textContent = document.getElementById('allowanceCharge-localized-remove-button-text').textContent;
    remButton.onclick = function() {
      this.parentNode.remove();
    };

    textSpan = document.createElement('span');

    li = document.createElement('li');
    li.className = 'list-group-item d-flex justify-content-between align-items-center';
    li.appendChild(textSpan);
    li.appendChild(remButton);
    li.addEventListener('click', editAllowanceCharge);
  } else {
    li = updatedACListItem;
    textSpan = li.querySelector('span');

    // Clean old values
    const inputsAndSelects = li.querySelectorAll('input, select');
    inputsAndSelects.forEach(element => element.remove());
  }
  textSpan.textContent = document.getElementById('allowanceCharge-localized-list-item-text').textContent + ' ' + allowanceChargeListNumber;

  // Clone form inputs as hidden list inputs
  Array.from(document.getElementById('allowanceChargeForm').elements).forEach(input => {
    var clone = input.cloneNode(true);
    clone.setAttribute('hidden', true);
    clone.removeAttribute('required');
    if (input.tagName.toLowerCase() === 'select') {
      copySelection(input, clone);
    } else if (input.type === 'text') {
      input.value = input.value.trim();
    }
    li.appendChild(clone);
  });

  if (!allowanceChargeUpdated) {
    var acList = document.getElementById('allowance-charge-list');
    acList.appendChild(li);
  }
};